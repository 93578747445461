<template>
<div>
    <van-nav-bar title="请假管理" left-text="返回" @click-left="back" fixed left-arrow style="z-index:1000" />
<div class="content">
    <van-tabs v-model="active" @change="activeChange">
        <!-- <van-tab title="请假填报" :name="0" v-if="leaveFil"></van-tab> -->
        <van-tab title="请假填报" :name="0" v-if="leaveFil && !isSyncData"></van-tab>
        <van-tab title="请假单列表" :name="1" v-if="leaveSingleList"></van-tab>
        <van-tab title="请假审核" :name="2" v-if="leaveAudit && !isSyncData"></van-tab>
        <van-tab title="假期剩余表" :name="3" v-if="isSyncData"></van-tab>
    </van-tabs>

    <div class="edit" v-if="active == 0">
        <van-form class="edit_form" ref="loginForm" label-width="120">
                <!-- 请假类型 -->
                <van-field v-model="editForm.leaveType" label="请假类型" @click="typeShow = true" readonly clickable>
                    <template #input>{{typeList[editForm.leaveType]}}</template>
                    <template #extra>
                        <span v-if="editForm.leaveType == 6" style="color: #C8C9CC">
                            {{ txselnum }} {{ editForm.timeType == 0 ? '天' : '小时' }}
                        </span>
                    </template>
                </van-field>
                <van-popup v-model="typeShow" position="bottom">
                    <van-picker
                    show-toolbar
                    :columns="typeList"
                    @confirm="typeChange"
                    @cancel="typeShow = false;$forceUpdate();"/>
                </van-popup>
                <!-- 请假人 -->
                <van-field v-model="editForm.ownerId" label="请假人" @click="ownerIdShow = true" :readonly="!canExamine" clickable required :rules="[{ required: true, message: '请选择请假人' }]">
                    <template #input>
                        <span v-if="user.userNameNeedTranslate == 1 && formshowText.name"><TranslationOpenDataText type='userName' :openid='formshowText.name'></TranslationOpenDataText></span>
                        <span v-else>{{formshowText.name}}</span>
                    </template>
                </van-field>
                <van-popup v-model="ownerIdShow" position="bottom" v-if="canExamine">
                    <div style="minHeight:300px;">
                        <van-radio-group v-model="userRadio" v-if="user.userNameNeedTranslate == '1'">
                            <van-radio v-for="item in userList" :key="item.id" :name="item" class="userCheckbox">
                                <TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText>
                            </van-radio>
                        </van-radio-group>
                        <van-radio-group v-model="userRadio" v-else>
                            <van-radio v-for="item in userList" :key="item.id" :name="item" class="userCheckbox">{{item.name}}</van-radio>
                        </van-radio-group>
                        <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="ownerIdChange()">确定</van-button>
                    </div>
                </van-popup>
                <!-- 电话 -->
                <van-field label="电话" v-model="editForm.tel" type="number" required :rules="[{ required: true, message: '请输入联系电话' }]"></van-field>
                <!-- 按天/小时请假 -->
                <van-field label="时长单位" readonly>
                    <template #input>
                        <van-radio-group v-model="editForm.timeType" direction="horizontal" @change="timeTypeChange">
                            <van-radio name="0">按天</van-radio>
                            <!-- <van-radio name="1" v-if="editForm.leaveType != 6">按小时</van-radio> -->
                            <van-radio name="1">按小时</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <!-- 选择日期 -->
                <van-field :label="editForm.timeType == '0' ? '选择日期' : '选择时间'"  required v-model="formshowText.dateTitle" @click="dateShow()" readonly clickable :rules="[{ required: true, message: editForm.timeType == '0' ? '请选择日期' : '请选择时间' }]">
                    <template #input><span>{{formshowText.dateTitle}}</span></template>
                </van-field>
                    <!-- 按天 -->
                <van-calendar v-model="dateShow_day" type="range" :allow-same-day="true" :show-confirm="false" @confirm="date_dayChange" :min-date="minDate" :max-date="maxDate" />
                    <!-- 按小时 -->
                <van-popup v-model="dateShow_hour" position="bottom">
                    <van-datetime-picker
                    type="date"
                    title="选择填报日期"
                    @confirm="date_hourChange"
                    v-model="currentDate"
                    @cancel="dateShow_hour = false;$forceUpdate();"
                    :min-date="minDate"
                    :max-date="maxDate"/>
                </van-popup>
                <!-- 请假天数 -->
                <van-field label="请假天数(天)" v-if="editForm.timeType == '0'" @blur="dayBlur()" v-model="editForm.timeDays" type="number" :rules="[{ required: true, message: '请输入请假天数' }]"></van-field>
                <!-- 请假时长 -->
                <van-field label="请假时长(小时)" v-else v-model="editForm.timeHours" @blur="hourBlur()" type="number" :rules="[{ required: true, message: '请输入请假时长' }]"></van-field>
                <!-- 备注 -->
                <van-field v-model="editForm.remark" label="备注" type="textarea" :autosize="{minHeight:100}"></van-field>
        </van-form>
        <!-- 提交 -->
        <div class="form_btn" style="position:fixed; bottom:0px;width:100%;">
            <div style="padding-bottom:10px;" v-if="editForm.id">
                <van-button square block type="default" @click="clearEditForm()" style="width:100%;float:left;">清除</van-button>
            </div>
            <div style="padding-bottom:10px;">
                <van-button square block type="info" @click="submitLeave()" :disabled="txselnum == 0 && editForm.leaveType == 6 && editForm.ownerId != '' ? true : false" :loading="submitLoading" style="width:100%;float:left;">提交</van-button>
            </div>
        </div>
    </div>
    <div class="list" v-if="active == 1">
        <van-collapse v-model="activeName" accordion class="list_collapse">
                <van-collapse-item v-for="item in leaveList" :key="item.id" title="标题1" :name="item.id">
                    <template #title>
                        <div class="collapse_label_l">请假人：
                            <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.ownerName'></TranslationOpenDataText></span>
                            <span v-else>{{item.ownerName}}</span>
                        </div>
                        <div class="collapse_label_r">电话：{{item.tel}}</div>
                        <div class="collapse_label_l">请假类型：{{typeList[item.leaveType]}}</div>
                        <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{statusList[item.status]}}</span></div>
                    </template>
                    <div class="wrapper">
                        <div><span>请假开始时间：</span><span>{{item.startDate}}</span></div>
                        <div><span>请假结束时间：</span><span>{{item.endDate}}</span></div>
                        <div><span>请假天数：</span><span>{{item.timeDays ? item.timeDays + '天' : '/'}}</span></div>
                        <div><span>请假时长：</span><span>{{item.timeHours ? item.timeHours + '小时' : '/'}}</span></div>
                        <div><span>备注：</span><span>{{item.remark}}</span></div>
                    </div>
                    <div class="operation" v-if="((item.status != 0 && item.ownerId == user.id) || leaveAll) && !isSyncData">
                        <van-button v-if="item.status == 3 || item.status == 2" size="small" type="info" @click="submitAgain(item)">重新提交</van-button>
                        <van-button v-if="item.status == 3 || item.status == 2" style="margin-left:10px" size="small" type="danger" @click="deleteLeave(item.id)">删除</van-button>
                        <van-button v-if="item.status == 1" style="margin-left:10px" size="small" type="default" @click="cancelLeave(item.id)">撤回</van-button>
                    </div>
                </van-collapse-item>
            </van-collapse>
    </div>
    <div class="audit" v-if="active == 2">
            <van-collapse v-model="auditName" accordion class="list_collapse">
                <van-collapse-item v-for="item in auditList" :key="item.id" title="标题2" :name="item.id">
                    <template #title>
                        <div class="collapse_label_l">请假人：
                            <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.ownerName'></TranslationOpenDataText></span>
                            <span v-else>{{item.ownerName}}</span>
                        </div>
                        <div class="collapse_label_r">电话：{{item.tel}}</div>
                        <div class="collapse_label_l">请假类型：{{typeList[item.leaveType]}}</div>
                        <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{statusList[item.status]}}</span></div>
                        <div class="operation">
                            <van-button size="small" type="info" :loading="item.approveLoading" @click.stop="approveLeave(item)">通过</van-button>
                            <van-button style="margin-left:15px" size="small" type="danger" @click.stop="denyLeave(item.id)">驳回</van-button>
                        </div>
                    </template>
                    <div class="wrapper">
                        <div><span>请假开始时间：</span><span>{{item.startDate}}</span></div>
                        <div><span>请假结束时间：</span><span>{{item.endDate}}</span></div>
                        <div><span>请假天数：</span><span>{{item.timeDays ? item.timeDays + '天' : '/'}}</span></div>
                        <div><span>请假时长：</span><span>{{item.timeHours ? item.timeHours + '小时' : '/'}}</span></div>
                        <div><span>备注：</span><span>{{item.remark}}</span></div>
                    </div>
                </van-collapse-item>
            </van-collapse>
            <van-popup v-model="denyReasonDialog" position="bottom" closeable >
                <van-cell>请输入原因</van-cell>
                <van-field class="form_input"
                    v-model="denyParm.denyReason" name="reason" type="textarea" placeholder="请输入您决定驳回的原因"
                    rows="3" autosize  />
                <van-button style="width:100%;" type="info" :loading="denyLoading" @click="deny()">提交</van-button>
            </van-popup>
    </div>
    <div class="audit" v-if="active == 3">
            <van-collapse v-model="auditName" accordion class="list_collapse">
                <van-collapse-item v-for="item in dingDingLeaveQtList" :key="item.id" title="标题2" :name="item.id">
                    <template #title>
                        <div class="collapse_label_l">员工姓名：
                            <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                            <span v-else>{{item.name}}</span>
                        </div>
                        <div class="collapse_label_r">总额度：{{item.quotaInDays}}天</div>
                        <div class="collapse_label_l">已使用：{{item.usedInDays}}天</div>
                        <div class="collapse_label_l">剩余额度：{{item.leftInDays}}天</div>
                        <div class="collapse_label_l" style="width: 100%">有效期开始日期：{{item.startTime}}</div>
                        <div class="collapse_label_l" style="width: 100%">有效期结束日期：{{item.endTime}}</div>
                    </template>
                    <div class="wrapper">
                        <div><span>总额度：</span><span>{{item.quotaInDays}}天</span></div>
                        <div><span>已使用：</span><span>{{item.usedInDays}}天</span></div>
                        <div><span>剩余额度：</span><span>{{item.leftInDays}}天</span></div>
                        <div><span>有效期开始日期：</span><span>{{item.startTime}}</span></div>
                        <div><span>有效期结束日期：</span><span>{{item.endTime}}</span></div>
                    </div>
                </van-collapse-item>
            </van-collapse>
    </div>
</div>
</div>
</template>

<script>
export default {
    data() {
        return {
            isSyncData: 0,
            user: JSON.parse(localStorage.userInfo),
            dingDingLeaveQtList: [],
            canExamine: false,
            leaveAll: false,
            leaveSingleList: false,
            leaveAudit: false,
            leaveFil: false,
            active: 0,
            txselnum: 0,
            txselnumTwo: 0,
            editForm: {
                leaveType: 0,
                ownerId: '',
                tel: '',
                startDate: '',
                endDate: '',
                timeType: '0',
                timeDays: 0,
                timeHours: 8,
                remark: ''
            },
            formshowText: {
                name: '',
                dateTitle: ''
            },
            userRadio: null,
            userList: [],
            typeList: ['事假','病假','年假','产假','婚假','丧假','调休假','陪产假','其他'],
            currentDate: new Date(),
            minDate: new Date(2020,0,1),
            maxDate: new Date(2030,11,31),

            ownerIdShow: false,
            canExamine: false,
            dateShow_day: false,
            dateShow_hour: false,
            typeShow: false,
            submitLoading: false,



            activeName: '',
            leaveList: [],
            statusList: ['审核通过','待审核','已驳回','已撤销'],
            statusClass: ['','waiting','rejected',''],

            auditName: '',
            auditList: [],
            denyReasonDialog: false,
            denyLoading: false,
            denyParm: {
                id: '',
                denyReason: ''
            }

            
        }
    },
    mounted() {
        this.isSyncData = this.user.timeType.syncDingding || this.user.timeType.syncFanwei;
        console.log(this.isSyncData)
        this.leaveSingleList = true
        for(let i in this.user.functionList){
            if(this.user.functionList[i].name == '查看全部请假单'){
                this.leaveAll = true
            }
            if(this.user.functionList[i].name == '请假审核'){
                this.leaveAudit = true
            }
            if(this.user.functionList[i].name == '请假填报'){
                this.leaveFil = true
            }
        }
        if(this.isSyncData) {
            this.getLeaveList()
        } else {
            if(this.leaveAudit) {
                this.active = '2'
                this.getAuditList()
            }
            if(this.leaveFil) {
                this.active = '0'
            }
        }
        
        // if(!this.canExamine){
            this.editForm.ownerId = this.user.id
            this.formshowText.name = this.user.name
            this.editForm.timeHours = this.user.timeType.allday
            this.getTxsel()
        // }else{
        //     this.getUserList()
        // }
    },
    methods: {
        back(){
            history.back();
        },
        getDaysBetween(date1,date2){
            let  startDate = Date.parse(date1);
            let  endDate = Date.parse(date2);
            if (startDate>endDate){
                return 0;
            }
            if (startDate==endDate){
                return 1;
            }
            let days=(endDate - startDate)/(1*24*60*60*1000);
            return  days + 1;
        },
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },
        activeChange(){
            sessionStorage.setItem('page',JSON.stringify(this.active))
            if(this.active == 1){
                this.getLeaveList()
            }
            if(this.active == 2){
                this.getAuditList()
            }
            if(this.active == 3) {
                this.getDingDingLeaveQt()
            }
        },
// #region 
        ownerIdChange(){
            this.editForm.ownerId = this.userRadio ? this.userRadio.id : ''
            this.formshowText.name = this.userRadio ? this.userRadio.name : ''
            this.ownerIdShow = false
        },
        timeTypeChange(){
            if(this.editForm.timeType == '0'){
                if(this.editForm.startDate && this.editForm.endDate){
                    this.formshowText.dateTitle = this.editForm.startDate+'\u3000至\u3000'+this.editForm.endDate
                }else{
                    this.formshowText.dateTitle = ''
                }
                this.txselnum = this.txselnumTwo > 0.5 ? this.txselnumTwo.toFixed(1) : 0
            }else{
                if(this.editForm.startDate){
                    this.formshowText.dateTitle = this.editForm.startDate
                }else{
                    this.formshowText.dateTitle = ''
                }
                this.txselnum = this.dayToHourConversion(this.txselnumTwo)
            }
            
        },
        dayToHourConversion(val) {
            let value = +val;  
            let workHours = 8;   
            let result = value * workHours; 
            return result.toFixed(1);
        },
        dateShow(){
            
            if(this.editForm.timeType == '0'){
                this.dateShow_day = true
            }else{
                this.dateShow_hour = true
            }
        },
        date_dayChange(value,date){
            this.dateShow_day = false
            this.editForm.startDate = this.formatDate(value[0])
            this.editForm.endDate = this.formatDate(value[1])
            this.formshowText.dateTitle = this.formatDate(value[0])+'\u3000至\u3000'+this.formatDate(value[1])
            this.editForm.timeDays = this.getDaysBetween(value[0],value[1])

            this.$axios.post('/leave-sheet/leaveDays', {
                startDate: this.editForm.startDate,
                endDate: this.editForm.endDate
            })
            .then(res => {
                if(res.code == "ok") {
                    this.editForm.timeDays = res.data
                } else {
                    this.$toast.fail(res.msg);
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        date_hourChange(value){
            this.editForm.startDate = this.formatDate(value)
            this.formshowText.dateTitle = this.editForm.startDate
            this.dateShow_hour = false
        },
        dayBlur(){
            if(this.editForm.startDate && this.editForm.endDate){
                if(this.editForm.timeDays*1 < this.getDaysBetween(this.editForm.startDate,this.editForm.endDate) - 1){
                    this.editForm.timeDays = this.getDaysBetween(this.editForm.startDate,this.editForm.endDate) - 1
                }else if(this.editForm.timeDays*1 > this.getDaysBetween(this.editForm.startDate,this.editForm.endDate)){
                    this.editForm.timeDays = this.getDaysBetween(this.editForm.startDate,this.editForm.endDate)
                }
            }
        },
        hourBlur(){
            if(this.editForm.timeHours*1 > this.user.timeType.allday){
                this.editForm.timeHours = this.user.timeType.allday
            }
            if(this.editForm.timeHours*1 < 0){
                this.editForm.timeHours = 0.5
            }
        },

        typeChange(value,key){
            this.editForm.leaveType = key
            this.typeShow = false
        },
        submitLeave(){
            this.$refs.loginForm.validate().then(()=>{
                console.log('success');
                if(!this.editForm.endDate) {
                    this.editForm.endDate = this.editForm.startDate
                }
                console.log(this.editForm, '<==== 将要提交的数据')
                this.submitLoading = true
                this.$axios.post("/leave-sheet/add", this.editForm)
                .then(res => {
                    this.submitLoading = false
                    if(res.code == "ok") {
                        this.editForm = {
                            leaveType: 0,
                            ownerId: this.editForm.ownerId,
                            tel: this.editForm.tel,
                            startDate: '',
                            endDate: '',
                            timeType: '0',
                            timeDays: 0,
                            timeHours: this.user.timeType.allday,
                            remark: ''
                        }
                        this.formshowText.dateTitle = ''
                        this.$toast.success('提交成功');
                    } else {
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.submitLoading = false;this.$toast.clear();console.log(err)});
            }).catch(()=>{})
        },
        clearEditForm(){
            this.editForm = {
                leaveType: 0,
                ownerId: this.editForm.ownerId,
                tel: this.editForm.tel,
                startDate: '',
                endDate: '',
                timeType: '0',
                timeDays: 0,
                timeHours: this.user.timeType.allday,
                remark: ''
            }
            this.formshowText.dateTitle = ''
        },
        getDingDingLeaveQt(){
            this.$axios.post("/user-yearleave-setting/getDingDingLeaveQt", {
                companyId: this.user.companyId,
                pageIndex: 1
            })
            .then(res => {
                this.dingDingLeaveQtList = res.data.records
            }).catch(err=> {this.submitLoading = false;this.$toast.clear();console.log(err)});
        },
// #endregion

// #region
        cancelLeave(pid){
            this.$dialog.confirm({
                message: '确认撤回？',
            })
            .then(() => {
                // on confirm
                this.$axios.post("/leave-sheet/cancel", {id: pid})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.success('撤回成功')
                        this.getLeaveList()
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();console.log(err)});
            })
            .catch(() => {
                // on cancel
            });
        },
        deleteLeave(pid){
            this.$dialog.confirm({
                message: '确认删除？',
            })
            .then(() => {
                // on confirm
                this.$axios.post("/leave-sheet/delete", {id: pid})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.success('删除成功')
                        this.getLeaveList()
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();console.log(err)});
            })
            .catch(() => {
                // on cancel
            });
            
        },
        submitAgain(item){
            this.editForm = item
            if(this.editForm.startDate && !this.editForm.endDate){
                this.$set(this.editForm,'timeType','1')
            }else{
                this.$set(this.editForm,'timeType','0')
            }
            this.timeTypeChange()
            this.active = 0
        },
// #endregion

        approveLeave(item){
            item.approveLoading = true
            this.$axios.post("/leave-sheet/approve", {id: item.id})
            .then(res => {
                if(res.code == "ok") {
                    this.$toast.success('已通过')
                    item.approveLoading = false
                    this.getAuditList()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
            
        },
        denyLeave(pid){
            this.denyParm.id = pid
            this.denyReasonDialog = true
        },
        deny(){
            this.denyLoading = true
            this.$axios.post("/leave-sheet/deny", this.denyParm)
            .then(res => {
                if(res.code == "ok") {
                    this.$toast.success('已驳回')
                    this.denyReasonDialog = false
                    this.denyLoading = false
                    this.getAuditList()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },

        getUserList(){
            this.$axios.post("/user/getSimpleActiveUserList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.userList = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getTxsel(){
            this.$axios.post("/leave-sheet/getOTAvaiDays", {userId: this.user.id})
            .then(res => {
                if(res.code == "ok") {
                    if(res.data < 0.5){
                        this.txselnum = 0
                    }else{
                        this.txselnum = res.data.toFixed(1)
                    }
                    this.txselnumTwo = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getLeaveList(){
            let parameter = {
                pageIndex: 1,
                pageSize: 999,
                // status: '',
                // startDate: '',
                // endDate: '',
                // ownerId: '',
                // leaveType: ''
            }
            this.$axios.post("/leave-sheet/list", parameter)
            .then(res => {
                if(res.code == "ok") {
                    this.leaveList = res.data.records
                } else {
                    this.$toast.fail('获取失败:'+res.smg);
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getAuditList(){
            this.$axios.post("/leave-sheet/auditList", {
                pageSize: 999,
                pageIndex: 1
            }).then(res => {
                if(res.code == "ok") {
                    this.auditList = res.data.records
                    for(let i in this.auditList){
                        this.$set(this.auditList[i],'approveLoading',false)
                    }
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        }
    },
}
</script>

<style lang="less" scoped>
.content{
    margin-top: 46px;
    overflow: auto;
    .edit{
        .userCheckbox {
            padding: 10px;
        }
        padding-bottom: 46px;
        .form_btn{
            z-index: 1000;
        }
    }
    .list{
        .list_collapse>div{
            margin: 4px;
        }
        .list_collapse{
            .collapse_label_l{
                width: 50%;
                padding: 4px;
                display: inline-block;
            }
            .collapse_label_r{
                width: 50%;
                padding: 4px;
                display: inline-block;
                .waiting {
                    color:orange;
                }
                .rejected {
                    color:red;
                }
            }
            .wrapper{
                div{
                    margin: 8px 16px;
                    zoom: 1; 
                }
                div:after{
                    content: ""; 
                    display: block; 
                    height: 0; 
                    clear: both; 
                    visibility: hidden; 
                }
                div span:nth-child(1){
                    width: 40%;
                    float: left;
                }
                div span:nth-child(2){
                    width: 60%;
                    float: left;
                }
            }
            .operation{
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        
    }
    .audit{
        .list_collapse>div{
            margin: 4px;
        }
        .list_collapse{
            
            .collapse_label_l{
                width: 50%;
                padding: 4px;
                display: inline-block;
            }
            .collapse_label_r{
                width: 50%;
                padding: 4px;
                display: inline-block;
                .waiting {
                    color:orange;
                }
                .rejected {
                    color:red;
                }
            }
            .operation{
                margin-top: 5px;
                padding-top: 5px;
                border-top: 0.5px solid #ebedf0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button{
                    width: 1.2rem;
                }
            }
            .wrapper{
                div{
                    margin: 8px 16px;
                    zoom: 1; 
                }
                div:after{
                    content: ""; 
                    display: block; 
                    height: 0; 
                    clear: both; 
                    visibility: hidden; 
                }
                div span:nth-child(1){
                    width: 40%;
                    float: left;
                }
                div span:nth-child(2){
                    width: 60%;
                    float: left;
                }
            }
            .lookup{
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            
        }
        
    }
}
</style>
<style>
.edit_form .invoice .van-field__label{
    color: #999;
}
.edit_form .invoice .van-field__control{
    color: #999;
}
</style>